
<template>
    <div class="hide_mask">

    </div>
</template>
<script>
import { sendcode } from "@/network/gitlab";
export default {
  mounted() {
    const ids = this.$route.query.ids ? this.$route.query.ids.split(','): [];
    const params = {
      projectId: ids[0],
      gitlabConfigId: ids[1],
      data: {
        code: this.$route.query.code
      }
    }
    sendcode(params).then((res) => {
      window.close()
    }).catch((error) => {
      this.$message({
        type: "warning",
        message: this.$t('addAuthentication.index'),
      });
    })
  }
}
</script>
<style lang="scss" scoped>
.hide_mask{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    left: 0;
    top: 0;
}
</style>
